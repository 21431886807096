import { Chip } from '@mui/material';
import type { FC } from 'react';
import { PoweredByStravaIcon } from '../../img/powered-by-strava.js';

interface Props {
  format: string;
  onClick?: () => void;
}

export const OriginalFormat: FC<Props> = ({ format, onClick }) => {
  if (format === 'strava')
    return (
      <PoweredByStravaIcon
        onClick={onClick}
        sx={{ height: { xs: 12, md: 20 } }}
      />
    );
  return <Chip onClick={onClick} label={format} size="small" />;
};
